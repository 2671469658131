import { Layout, Link, ViewTable } from "@/components";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import WebAuth from "@/web/WebAuth";
import { LibAuthCode } from "lib/Auth";
import LibEnum from "lib/enum";
import React from "react";

export default class ManageDetailReport extends React.PureComponent<RouterPropsType> {

  private columns = [
    {
      title: '单号',
      align: 'center',
      width: 200,
      dataIndex: 'bookId',
      render: (value, row) => {
        const type = this.props.match?.params.type;
        if (!row.relevanceId || type === 'fee') return value;
        let to = '';
        if (type === LibEnum.BOOK_TYPE.SALE.value && WebAuth.hasAuth(LibAuthCode.saleOrder.view)) {
          to = routerMap.sale_order_detail.getPath(row.relevanceId, '11');
        }
        if (type === LibEnum.BOOK_TYPE.SALE_RETURN.value && WebAuth.hasAuth(LibAuthCode.saleReturnOrder.view)) {
          to = routerMap.saleReturnOrderDetail.getPath(row.relevanceId);
        }
        if (type === LibEnum.BOOK_TYPE.PURCHASE.value && WebAuth.hasAuth(LibAuthCode.purchaseOrder.view)) {
          routerMap.purchaseOrderDetail.getPath(row.relevanceId)
        }
        if (type === LibEnum.BOOK_TYPE.PURCHASE_RETURN.value && WebAuth.hasAuth(LibAuthCode.purchaseReturnOrder.view)) {
          routerMap['purchaseReturn.view.detail'].getPath(row.relevanceId)
        }
        return <Link to={to}>{value}</Link>
      }
    },
    {
      title: '交易时间',
      align: 'center',
      width: 200,
      dataIndex: 'datetime',
    },
    {
      title: '往来单位',
      width: 200,
      dataIndex: 'targetName',
    },
    {
      title: '交易金额',
      align: 'right',
      width: 120,
      dataIndex: 'actualAmount',
    },
    {
      title: '交易类型',
      align: 'center',
      dataIndex: 'bookTypeDesc',
      width: 120,
    },
    {
      title: '备注说明',
      width: 300,
      titleAlign: 'left',
      dataIndex: 'remark',
    },
  ];

  render() {
    let type = this.props.match?.params.type;
    const date = this.props.match?.params.date;

    let bookTypeList = [type];
    if (type === 'fee') {
      bookTypeList = [
        LibEnum.BOOK_TYPE.FEE_ELECTRICITY,
        LibEnum.BOOK_TYPE.FEE_OIL,
        LibEnum.BOOK_TYPE.FEE_OTHER,
        LibEnum.BOOK_TYPE.FEE_SALARY,
        LibEnum.BOOK_TYPE.FEE_WATER,
      ];
    }

    const title = {
      [LibEnum.BOOK_TYPE.SALE.value]: '经营明细表-订货收款',
      [LibEnum.BOOK_TYPE.SALE_RETURN.value]: '经营明细表-订货退货付款',
      [LibEnum.BOOK_TYPE.PURCHASE.value]: '经营明细表-进货付款',
      [LibEnum.BOOK_TYPE.PURCHASE_RETURN.value]: '经营明细表-进货退货收款',
      fee: '经营明细表-费用支出',
    }[type]
    return <Layout.Page>
      <Layout.Toolbar title={title}></Layout.Toolbar>
      <ViewTable
        flex1
        columns={this.columns as any}
        fetch={(data: any) => {
          return WebApi.book_paging({...data, bookTypeList, dateRange: [date, date]});
        }}
      />
    </Layout.Page>
  }
}